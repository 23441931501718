<template>
  <gl-modal
    v-bind="$attrs"
    class="counterparty__crud-modal"
    :class="[{ 'counterparty__crud-modal--hide-submit': !isCreate }]"
    :disabled-submit="!isCreate"
    full-buttons
    :submit-title="isCreate ? 'Create' : 'View'"
    :title="isCreate ? 'Create new report' : 'View report params'"
    width="530"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <gl-input
      v-model="model.title"
      class="mb-4"
      :disabled="!isCreate"
      :height="38"
      label="Report name"
      name="Report name"
      placeholder="Add report name"
      rules="required"
    />
    <GlSelect
      v-model="model.direction"
      class="mb-4"
      :clearable="false"
      :disabled="!isCreate"
      label="Direction"
      label-select="name"
      :options="directionsList"
      placeholder="Choose Direction"
      :reduce="option => option.value"
      rules="required"
    />
    <GlCoinSelect
      class="mb-4"
      :disabled="!isCreate"
      label="Blockchain"
      @change="handleCoinSelect"
    />
    <GlSelect
      v-if="$can('use', 'eth')"
      v-model="token"
      class="mb-4"
      clearable
      :disabled="!isCreate"
      label="Token"
      label-select="symbol"
      :loading="tokensLoading"
      :options="tokensList"
      placeholder="Select Token"
      rules="required"
      searchable
      @open="scrollToAim('.vs__dropdown-menu', 'end')"
    >
      <template #selected-slot="{ data }">
        <GlImg
          v-if="data.icon"
          class="mr-1"
          :src-val="data.icon"
        />
        {{ data.symbol }}
      </template>
      <template #option-slot="{ data }">
        <GlImg
          v-if="data.icon"
          class="mr-1"
          :src-val="data.icon"
        />
        {{ data.symbol }}
      </template>
    </GlSelect>
    <GlSelect
      v-model="model.address.owner"
      class="mb-4 tagged"
      :class="{
        'hide-select-options': searchedOwner.length < searchedOwnerMinLength,
      }"
      clearable
      :disabled="!isCreate"
      label="Entity name"
      label-select="owner"
      multiple
      :options="ownersList"
      placeholder="Choose entity (enter 3 or more characters)"
      :rules="ownerSelectRules"
      searchable
      @open="scrollToAim('.vs__dropdown-menu', 'end')"
      @search="searchByOwner"
    />
    <!-- <InputAutoAdd
      :coin-data-local="coinDataLocal"
      :disabled="!isCreate"
      input-name="Add address or cluster"
      :main-data="model.targets"
      :target-rules="targetRules"
      @add-target-input="addTargetInput"
      @input="onInputTarget"
      @remove-target-input="
        i => {
          removeTargetInput(i)
        }
      "
    /> -->
    <GlDatePicker
      class="mb-4"
      :disabled="!isCreate"
      :disabled-range="disabledRange"
      label="Choose date & time"
      name="Date range"
      :rules="dateRangeSelectRules"
      :value="listDates"
      @apply="setDate"
      @cancel="handleDateClose"
      @change="setDate"
      @clear="handleDateClose"
      @pick="handlePickDate"
    />

    <gl-input
      v-if="isCustomAccess"
      v-model="model.address_or_cluster"
      class="mb-4"
      :disabled="!isCreate"
      :height="38"
      label="Address or cluster ID"
      name="address_or_cluster"
      placeholder="Add address or cluster ID"
    />
    <gl-input
      v-if="isCustomAccess"
      v-model.number="model.maxDepth"
      class="mb-4"
      :disabled="!isCreate"
      :height="38"
      label="Max Depth"
      name="Max Depth"
      placeholder="Add max depth"
      type="number"
    />
    <gl-input
      v-if="isCustomAccess"
      v-model.number="model.thresholdAmount"
      class="mb-4"
      :disabled="!isCreate"
      :height="38"
      label="Threshold Amount"
      name="Threshold Amount"
      placeholder="Add threshold amount"
      type="number"
    />

    <!-- <GlSelect
      v-if="!isCreate"
      v-model="model.types"
      class="mb-4 tagged"
      clearable
      label="Type"
      label-select="name"
      multiple
      :options="typesList"
      placeholder="Choose type"
      searchable
      @open="scrollToAim('.vs__dropdown-menu', 'end')"
    />
    <GlSelect
      v-if="!isCreate"
      v-model="model.tags"
      class="mb-4 tagged"
      clearable
      label="Tags"
      label-select="name"
      multiple
      :options="tagsList"
      placeholder="Choose tags"
      searchable
      @open="scrollToAim('.vs__dropdown-menu', 'end')"
    /> -->
  </gl-modal>
</template>

<script>
// Vuex
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'
import GlSelect from '@/components/gl-select.vue'
import GlCoinSelect from '@/components/gl-coin-select.vue'
import GlDatePicker from '@/components/gl-date-picker'
import InputAutoAdd from '@/pages/counterparty/components/InputAutoAdd'
import GlImg from '@/components/gl-img.vue'

// Utils
import { toSecondsStart, toSecondsEnd } from '@/utils/format-date'
import { scrollToViewSelector } from '@/utils/scroll-to'
import { featureAccess } from '@/utils/accesses'
import { tokensSorting } from '@/utils/cytoskape-ui-rules'

// Models
import {
  counterpartyReportFields,
  directionsList,
} from '@/pages/counterparty//models/counterparty-report-fields'
// Libs
import _ from 'lodash'
import moment from 'moment'

export default {
  components: {
    GlImg,
    GlModal,
    GlInput,
    GlSelect,
    GlCoinSelect,
    GlDatePicker,
    InputAutoAdd,
  },
  inheritAttrs: false,
  props: {
    reportType: {
      type: String,
      default: 'type',
    },
    reportTypeCondition: {
      type: String,
      default: 'create',
    },
    modelData: {
      type: Object,
      default: () => {},
    },
    typesList: {
      type: Array,
      default: () => [],
    },
    tagsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      model: counterpartyReportFields(),
      coinDataLocal: {},
      listDates: [],
      ownersList: [],
      directionsList,
      searchedOwner: '',
      searchedOwnerMinLength: 3,
      token: null,
      tokensLoading: false,
      tokensList: [],
      disabledDateRange: {
        start: null,
        end: null,
      },
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    ...mapGetters('user', ['isAdmin']),
    targetRules() {
      return `addressCustomCoinRegExp:${this.coinDataLocal?.addressRegex}`
        .replace('|', '$or$')
        .replace(',', '$comma$')
    },
    isCreate() {
      return this.reportTypeCondition === 'create'
    },
    ownerSelectRules() {
      if (this.isCustomAccess) return ''
      return 'required'
    },
    dateRangeSelectRules() {
      if (this.isAdmin) return 'required'
      return 'required|lessThanDateRange:94,day,3 months'
    },
    isCustomAccess() {
      return this.isAdmin && this.featureAccess('COUNTERPARTY_CUSTOM')
    },
  },
  watch: {
    modelData: {
      handler(val) {
        this.coinDataLocal = _.cloneDeep(this.coinData)
        this.model = _.cloneDeep(val)

        if (this.model.tx_hash && this.model.tx_hash.timestamp) {
          this.setListDates(this.model.tx_hash.timestamp)
        } else this.clearDate()

        // if (this.model && this.model.targets) {
        //   this.model.targets = this.model.targets.map(target => {
        //     return { ...target, loadCluster: false, addCluster: false }
        //   })
        // }
      },
    },
    '$attrs.value': {
      handler(value) {
        if (value) {
          this.clearTokensState()
          this.setTokens()
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA',
    }),
    ...mapActions({
      getAddressData: 'analytics/getAddressData',
      getAddressTokens: 'analytics/getAddressTokens',
      getTokens: 'analytics/getTokens',
    }),
    ...mapActions('tagging', ['getOwners']),
    toSecondsStart,
    toSecondsEnd,
    scrollToViewSelector,
    featureAccess,
    tokensSorting,
    onSubmit() {
      this.filterBeforeSubmit()
      this.$emit('confirm', this.model)
      if (Object.keys(this.coinDataLocal).length) {
        this.SET_COIN_TYPE(this.coinDataLocal.key)
        this.SET_COIN_DATA(this.coinDataLocal)
      }
      this.clearTokensState()
    },
    filterBeforeSubmit() {
      // this.model.targets = this.model.targets.map(e => {
      //   return {
      //     address: e.address,
      //     cluster: e.addCluster ? e.cluster : '',
      //   }
      // })

      if (this.model?.address?.owner?.length) {
        this.model.address.owner = this.model.address.owner.map(e => {
          return e.owner
        })
      }
      if (this.model?.address_or_cluster?.length) {
        const address_or_cluster = this.model.address_or_cluster
        if (address_or_cluster.match(/^[\d,\s]+$/)) {
          this.model.address = {
            cluster: address_or_cluster
              .split(',')
              .map(item => item.trim())
              .filter(e => e),
          }
        } else {
          this.model.address = address_or_cluster
            .split(',')
            .map(item => item.trim())
            .filter(e => e)
        }
      }
      if (this.token) {
        this.model.token = this.token.address
      }
    },
    handleCoinSelect(data) {
      this.model.address.owner = []
      this.model.Blockchain = data.key
      this.coinDataLocal = data
      this.clearTokensState()
      this.setTokens()
    },
    handleDateClose() {
      this.clearDate()
    },
    setDate(date) {
      const [from, to] = date

      if (from && to) {
        const [fromResult, toResult] = [
          this.toSecondsStart(from),
          this.toSecondsEnd(to),
        ]
        this.model.tx_hash.timestamp = {
          $gte: fromResult,
          $lte: toResult,
        }

        this.listDates = [
          new Date(
            String(moment.unix(fromResult).format('YYYY-MM-DD HH:mm:ss')),
          ),
          new Date(String(moment.unix(toResult).format('YYYY-MM-DD HH:mm:ss'))),
        ]
        // this.listDates = [from, to]
      } else this.clearDate()
    },

    setListDates(val) {
      if (val && val.$gte && val.$lte) {
        this.listDates = [
          new Date(String(moment.unix(val.$gte).format('YYYY-MM-DD HH:mm:ss'))),
          new Date(String(moment.unix(val.$lte).format('YYYY-MM-DD HH:mm:ss'))),
        ]
      }
    },
    handlePickDate(date) {
      const threeMonthsBefore = new Date(date)
      threeMonthsBefore.setMonth(date.getMonth() - 3)

      const threeMonthsAfter = new Date(date)
      threeMonthsAfter.setMonth(date.getMonth() + 3)

      const today = new Date()

      this.disabledDateRange.start = threeMonthsBefore
      this.disabledDateRange.end =
        threeMonthsAfter > today ? today : threeMonthsAfter
    },
    disabledRange(date) {
      const today = new Date()

      if (date > today) {
        return true
      }

      if (this.isAdmin) {
        return false
      }

      if (this.disabledDateRange.start && this.disabledDateRange.end) {
        return (
          date < this.disabledDateRange.start ||
          date > this.disabledDateRange.end
        )
      }

      return false
    },
    clearDate() {
      this.listDates = []
      this.disabledDateRange = {
        start: null,
        end: null,
      }
    },
    addTargetInput() {
      this.model.targets.push({
        address: '',
        cluster: '',
        loadCluster: false,
        addCluster: false,
      })
    },
    removeTargetInput(index) {
      if (this.isCreate) {
        this.model.targets.splice(index, 1)
      }
    },
    onInputTarget(index) {
      const targetItem = this.model.targets[index]
      const valid = new RegExp(this.coinDataLocal.addressRegex).test(
        targetItem.address,
      )
      if (!valid) {
        this.$toasted.global.error({ message: 'Search value is not valid' })
        this.model.targets[index].cluster = ''
        this.model.targets[index].addCluster = false
        return
      }
      if (
        this.model.targets &&
        this.model.targets.length &&
        this.model.targets.at(-1).address
      ) {
        this.addTargetInput()
      }
      this.model.targets[index].loadCluster = true
      this.getAddressData({ address: targetItem.address })
        .then(({ data, success }) => {
          if (!success) {
            this.$toasted.global.error({ message: `${data.message}` })
            return
          }
          this.model.targets[index].cluster = data.blockHeight
        })
        .finally(() => {
          this.model.targets[index].loadCluster = false
        })
    },
    scrollToAim(wrap, aim) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.scrollToViewSelector(wrap, aim)
        })
      }, 300)
    },

    searchByOwner(owner, loadingFn) {
      this.searchedOwner = owner
      if (owner && owner.length >= this.searchedOwnerMinLength) {
        loadingFn(true)
        this.getOwners({ owner })
          .then(data => {
            this.ownersList = data.items || []

            this.scrollToAim('.vs__dropdown-menu', 'end')
          })
          .catch(() => {
            this.ownersList = []
            loadingFn(false)
          })
          .finally(() => {
            loadingFn(false)
          })
      } else if (!owner) {
        loadingFn(true)
        this.ownersList = []
        loadingFn(false)
      }
    },
    setTokens() {
      if (!this.coinData.isNeedTokensCall) return
      this.tokensLoading = true

      this.getTokens({})
        .then(data => {
          this.tokensList = this.tokensSorting(data)

          this.token =
            this.tokensList.find(t => t.address == this.model.token) ||
            this.tokensList.find(t => t.address == '') ||
            null
        })
        .catch(() => {
          this.tokensLoading = false
        })
        .finally(() => {
          this.tokensLoading = false
        })
    },
    clearTokensState() {
      this.tokensList = []
      this.token = null
      this.tokensLoading = false
    },
  },
}
</script>

<style>
.counterparty__crud-modal .coin-select-base {
  margin-right: 0 !important;
}
.counterparty__crud-modal .coin-select-base .vs__dropdown-toggle,
.counterparty__crud-modal .vs__dropdown-toggle,
.counterparty__crud-modal .mx-input {
  min-height: 38px;
}

.counterparty__crud-modal .gl-modal__container {
  padding: 24px;
}
.counterparty__crud-modal .gl-modal__title {
  font-size: 20px;
  text-transform: unset;
}
.counterparty__crud-modal .gl-input__label,
.counterparty__crud-modal .gl-select__label {
  margin-bottom: 4px;
}

.counterparty__crud-modal .gl-select__wrapper input {
  font-size: 14px;
}
.counterparty__crud-modal .form-content-wrapper {
  padding-right: 4px;
}

.counterparty__crud-modal .form-content-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.counterparty__crud-modal .gl-input__error {
  top: unset;
}

.counterparty__crud-modal--hide-submit button[type='submit'] {
  display: none;
}
.counterparty__crud-modal--hide-submit button[type='reset'] {
  margin-right: 0 !important;
}
</style>
